<template>
    <div>
      <Header />
    </div>
    <br>
    <div class="container-fluid px-0">
        <router-view />
    </div>
</template>

<script>
import Header from '../../shared/navigation/Header.vue';

export default {
    name: 'Home',
    components: {
        Header
    }
};
</script>

<style scoped>
    .container-fluid {
        display: flex;
    }
</style>
